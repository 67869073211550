footer {
  position: relative;
  display: flex;
  height: 9vh;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-evenly;
  --linkc: #eee;
  --transitionT: 0.3s;
}
footer a {
  font-size: 4vh;
  --ul: 0%;
  color: var(--linkc);
  position: relative;
  opacity: var(--opacity);
}
footer a::after {
  content: "";
  width: var(--ul);
  height: 0.5vh;
  background-color: #eee;
  position: absolute;
  bottom: 2.5vh;
  left: -15%;
  opacity: var(--opacity);
  transition: all var(--transitionT) ease-in-out;
}
a,
a:active,
a:visited {
  text-decoration: none;
  transition: opacity var(--transitionT) ease-out;
  --opacity: 0.65;
}
a:hover {
  --opacity: 1;
  --ul: 130%;
  transition: all var(--transitionT) ease-in-out;
}
