header {
  height: 13vh;
  display: inline-flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: nowrap;
  width: 100%;
}

header h1 {
  font-size: 6vh;
  margin: 1vh 0;
}
