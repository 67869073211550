body {
  margin: 0;
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (prefers-reduced-motion) {
  * {
    animation: none;
  }
}
