.neon {
  font-family: "Sacramento", sans-serif;
  --red: #f11;
  --yellow: #fd1;
  --grey: #a8a5a38a;

  --transitionT: 0.9s;
  color: var(--red);
}

.neon footer button,
.neon main button {
  display: none;
}

.neon header {
  justify-content: space-evenly;
}

.neon h1 {
  padding: 0 1.5vh;
  display: inline;

  text-transform: capitalize;
  font-weight: lighter;

  --t: 1.1px;
  --tc: var(--red);
  color: #fff;
  text-shadow: 0 0 var(--t) #fff, 0 0 calc(var(--t) * 2) #fff,
    0 0 calc(var(--t) * 3) #fff, 0 0 calc(var(--t) * 8) var(--tc),
    0 0 calc(var(--t) * 16) var(--tc), 0 0 calc(var(--t) * 30) var(--tc),
    0 0 calc(var(--t) * 50) var(--tc), 0 0 calc(var(--t) * 70) var(--tc);

  --b: 0.35px;
  --bc: var(--yellow);
  border: 0.2rem solid #fff;
  border-radius: 3vh;
  box-shadow: 0 0 var(--b) #fff, 0 0 calc(var(--b) * 2) #fff,
    0 0 calc(var(--b) * 3) #fff, 0 0 calc(var(--b) * 7) var(--bc),
    0 0 calc(var(--b) * 12) var(--bc), 0 0 calc(var(--b) * 20) var(--bc),
    0 0 calc(var(--b) * 40) var(--bc), inset 0 0 calc(var(--b) * 30) var(--bc);
}

.neon button,
.neon .metal {
  --metal: #d7d7d7 15%, var(--grey) 35%, #4c68a569 45%, #a9a9a9 75%;
  background: linear-gradient(var(--metal));
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.neon button {
  padding: 1.5vh;
  margin: 1vh;

  --s-distance: 2vh;
  --s-width: 100%;
  position: relative;
  text-transform: lowercase;
  font-weight: bolder;
  font-size: 5vh;
  transition: all var(--transitionT);
}

.neon button::after,
.neon button::before {
  content: "";
  position: absolute;
  left: calc(2vh - var(--s-distance));
  height: 1vh;
  width: var(--s-width);
  background: radial-gradient(closest-side at 5%, var(--grey) 50%, transparent),
    radial-gradient(closest-side at 95%, var(--grey) 50%, transparent);
  transition: all calc(var(--transitionT) / 2) ease-in;
}

.neon button::after {
  bottom: var(--s-distance);
}
.neon button::before {
  top: var(--s-distance);
}

.neon button:hover {
  filter: sepia(25%);
  /* transform: rotate(-1deg); */
  --s-distance: 0.5vh;
  --s-width: 50%;
  transition: all var(--transitionT);
}
