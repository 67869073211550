main {
  display: flex;
  min-height: 78vh;
}

main > img {
  width: 0;
  height: 0;
}

main > div {
  --size: min(40rem, 70vmin);
  height: var(--size);
  width: var(--size);
  margin: 0 auto;
}

#line-face {
  background-image: url("../../assets/img/line-t.png");
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
}
