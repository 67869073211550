.default {
  font-family: "Sacramento", serif;

  --red: #f11;
  --yellow: #fd1;
  --grey: #444;
  --white: #ddd;

  --transitionT: 0.3s;

  color: var(--white);
}

.default button {
  --s: 2vh;
  font-size: calc(1.5 * var(--s));
  line-height: var(--s);
  margin: calc(1.75 * var(--s)) 0;
  padding: calc(0.25 * var(--s)) calc(1.5 * var(--s));
  height: calc(1.5 * var(--s));

  color: var(--white);
  background: var(--grey);
  position: relative;
  filter: drop-shadow(3vh 0 0 var(--white));
  transition: all var(--transitionT);
}

.default button:hover {
  padding: calc(0.25 * var(--s)) calc(0.5 * var(--s)) calc(0.25 * var(--s))
    calc(2.5 * var(--s));
  filter: drop-shadow(4vh 0 0 var(--white));
  transition: all var(--transitionT);
}

.default button::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  --s2: calc(0.99 * var(--s));
  border-left: var(--s2) solid #000;
  border-top: var(--s2) solid var(--grey);
  border-bottom: var(--s2) solid var(--grey);
}
.default button::before {
  content: "";
  position: absolute;
  right: calc(-1 * var(--s));
  bottom: 0;
  width: 0;
  height: 0;
  border-left: var(--s) solid var(--grey);
  border-top: var(--s) solid transparent;
  border-bottom: var(--s) solid transparent;
}

.default h1 {
  padding: 0.25vh 1vh;
  display: inline;
}
