.App {
  background: #000;
  padding: 0 10vmin;
}

button {
  all: unset;
  cursor: pointer;
}

.rainbow-bg {
  background: linear-gradient(
    333deg,
    #e41e00,
    #f17c7c,
    #ffd650,
    #f8fa88,
    #47c75d,
    #1de8b5,
    #053edb,
    #9400d8,
    #dd00f3
  );
  background-size: 2000% 2000%;
  animation: rotatebg 89s ease infinite;
}

.blur-pulse {
  transform: scale(1.05);
  animation: blurpulse 26s infinite;
}

@keyframes rotatebg {
  0% {
    background-position: 0% 85%;
  }
  25% {
    background-position: 20% 10%;
  }
  50% {
    background-position: 100% 15%;
  }
  75% {
    background-position: 75% 65%;
  }
  100% {
    background-position: 0% 85%;
  }
}

@keyframes blurpulse {
  0% {
    filter: blur(0);
  }
  48% {
    filter: blur(0);
  }
  50% {
    filter: blur(1px);
  }
  52% {
    filter: blur(0);
  }
  100% {
    filter: blur(0);
  }
}
